import PublishedSurvey from "./PublishedSurvey";

export default class SurveyResult {
  constructor() {
    this.id = null;
    this.employee = null;
    this.publishedSurvey = new PublishedSurvey();
    this.answers = [];
  }

  getQuestionAnswers() {
    const answers = [];
    this.publishedSurvey.survey.sections.forEach(section =>
      section.questions.forEach(question => {
        answers.push({
          question: question.id,
          answer: question.answer,
          choices: question.selectedChoices.map(choice => choice.id)
        });
      })
    );
    return answers;
  }
}
