<template>
  <validation-observer slim v-slot="{ handleSubmit }">
    <app-page-layout :title="titles[step]" @close="leavePage">
      <template #content>
        <v-row>
          <v-col cols="12">
            <v-window v-model="step">
              <v-window-item :value="0">
                <v-row>
                  <v-col cols="12" class="text-center">
                    <v-avatar size="60" :color="surveyResult.publishedSurvey.survey.color">
                      <span class="white--text text-h4">
                        {{ (surveyResult.publishedSurvey.survey.name || "").slice(0, 2).toUpperCase() }}
                      </span>
                    </v-avatar>
                  </v-col>
                  <v-col cols="12">
                    <p class="text-center text-h6">
                      {{ surveyResult.publishedSurvey.survey.name }}
                    </p>
                  </v-col>
                  <v-col cols="12" v-if="surveyResult.publishedSurvey.survey.description">
                    <p class="text-center text-h7">{{ $t("hr.survey.description") }}</p>
                    <p class="text-center font-weight-regular">
                      {{ surveyResult.publishedSurvey.survey.description }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <p class="text-center text-h7">{{ $t("hr.survey.section_count") }}</p>
                    <p class="text-center font-weight-regular">
                      {{ surveyResult.publishedSurvey.survey.sections.length }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <p class="text-center text-h7">{{ $t("hr.survey.question_count") }}</p>
                    <p class="text-center font-weight-regular">
                      {{ surveyResult.publishedSurvey.survey.questionCount }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <p class="text-center text-h7">{{ $t("hr.survey.estimated_duration") }}</p>
                    <p class="text-center font-weight-regular">{{ estimatedDuration }}</p>
                  </v-col>
                  <v-col cols="12">
                    <p class="text-center text-h7">{{ $t("hr.survey.end_date") }}</p>
                    <p class="text-center font-weight-regular">
                      {{ surveyResult.publishedSurvey.endDateTime | formattedDateTimeMinute }}
                    </p>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item :value="1">
                <v-row>
                  <v-col cols="12" class="text-center">
                    <v-avatar size="60" :color="surveyResult.publishedSurvey.survey.color">
                      <span class="white--text text-h4">
                        {{ (surveyResult.publishedSurvey.survey.name || "").slice(0, 2).toUpperCase() }}
                      </span>
                    </v-avatar>
                  </v-col>
                  <v-col cols="12">
                    <p class="text-center text-h6">
                      {{ surveyResult.publishedSurvey.survey.name }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <survey-section
                      v-for="(surveySection, index) in surveyResult.publishedSurvey.survey.sections"
                      v-model="surveyResult.publishedSurvey.survey.sections[index]"
                      :key="surveySection.rank"
                    />
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
      </template>
      <template #footer>
        <app-page-footer create>
          <template #left>
            <v-btn v-if="step === 0" :disabled="loading" rounded depressed @click="leavePage">{{
              $t("buttons.close")
            }}</v-btn>
            <v-btn v-else-if="step === 1" :disabled="loading" rounded depressed @click="leavePage">{{
              $t("hr.survey.exit")
            }}</v-btn>
          </template>
          <template #right>
            <v-btn v-if="step === 0" color="primary" depressed rounded :disabled="loading" @click="step = 1">
              {{ $t("hr.survey.start_survey") }}
            </v-btn>
            <template v-else>
              <v-btn
                color="teal"
                class="mr-2 white--text"
                depressed
                rounded
                :disabled="loading"
                @click="handleSubmit(validateAndSave)"
              >
                {{ $t("buttons.save") }}
              </v-btn>
              <v-btn
                color="primary"
                depressed
                rounded
                :disabled="loading"
                @click="handleSubmit(validateAndSaveAndFinish)"
              >
                {{ $t("hr.survey.save_and_finish") }}
              </v-btn>
            </template>
          </template>
        </app-page-footer>
      </template>
    </app-page-layout>
  </validation-observer>
</template>

<script>
  import SurveyResult from "../model/SurveyResult";
  import { SURVEY_RESULT } from "../query";

  export default {
    name: "SurveyStart",
    props: {
      id: [String, Number]
    },
    components: {
      AppPageFooter: () => import("@/layouts/app-page-footer"),
      SurveySection: () => import("../components/SurveySection")
    },
    data: vm => ({
      surveyResult: new SurveyResult(),
      loading: false,
      step: 0,
      titles: [vm.$t("hr.survey.survey_information"), vm.$t("hr.survey.complete_survey")]
    }),
    computed: {
      estimatedDuration() {
        let seconds = this.surveyResult.publishedSurvey.survey.questionCount * 20;
        let duration = this.$moment.duration(seconds, "seconds");
        return duration.humanize();
      }
    },
    watch: {
      id: {
        handler() {
          this.fetchSurveyResult();
        },
        immediate: true
      }
    },
    methods: {
      leavePage() {
        this.$router.push({ name: "survey_pending" });
      },
      saveSurvey() {
        const payload = { answers: this.surveyResult.getQuestionAnswers() };
        return new Promise((resolve, reject) => {
          this.$api.surveyService
            .finish(this.surveyResult.id, payload)
            .then(resolve)
            .catch(reject);
        });
      },
      validateAndSave() {
        this.loading = true;
        this.saveSurvey()
          .then(response => {
            if (response.status === 200) {
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      validateAndSaveAndFinish() {
        this.loading = true;
        this.saveSurvey()
          .then(response => {
            if (response.status === 200) {
              return this.$api.surveyService.complete(this.surveyResult.id);
            }
          })
          .then(response => {
            if (response.status === 200) {
              this.leavePage();
              this.$emit("success:save");
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      fetchSurveyResult() {
        this.loading = true;
        this.$apollo
          .query({
            query: SURVEY_RESULT,
            variables: {
              id: this.$helpers.decodeId(this.id)
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.surveyResult = Object.assign(this.surveyResult, this.prepareSurveyResult(data.surveyResult));
              this.$emit("detail-loaded:success", data.surveyResult);
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.loading = false));
      },
      prepareSurveyResult(surveyResult) {
        surveyResult.publishedSurvey.survey.sections.forEach(section =>
          section.questions.forEach(question => {
            question.answer = null;
            question.selectedChoice = null;
            question.selectedChoices = [];
            const questionAnswer = surveyResult.answers.find(answer => answer.question.id === question.id);
            if (questionAnswer) {
              question.answer = questionAnswer.answer;
              question.selectedChoices = questionAnswer.questionChoices;
              question.selectedChoices = question.choices.filter(choice =>
                questionAnswer.questionChoices.find(item => item.id === choice.id)
              );
              question.selectedChoice = question.selectedChoices[0];
            }
          })
        );
        return surveyResult;
      }
    }
  };
</script>

<style scoped></style>
